// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contacto-prensa-js": () => import("./../../src/pages/contacto_prensa.js" /* webpackChunkName: "component---src-pages-contacto-prensa-js" */),
  "component---src-pages-contacto-prensa-success-js": () => import("./../../src/pages/contacto_prensa_success.js" /* webpackChunkName: "component---src-pages-contacto-prensa-success-js" */),
  "component---src-pages-contacto-success-js": () => import("./../../src/pages/contacto_success.js" /* webpackChunkName: "component---src-pages-contacto-success-js" */),
  "component---src-pages-equipo-js": () => import("./../../src/pages/equipo.js" /* webpackChunkName: "component---src-pages-equipo-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-prensa-js": () => import("./../../src/pages/prensa.js" /* webpackChunkName: "component---src-pages-prensa-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

